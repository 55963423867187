import React from "react"
import {
  INTERNAL_TYPE_TO_STRAPI,
  STRAPI_TYPE_TO_TYPENAME,
} from "../DynamicZone"
import ProductCTA from "../ProductCTA"
import ProductHighlight from "../ProductHighlight"
import ProductQuote from "../ProductQuote"
import ProductVideo from "../ProductVideo"
import RichSection from "../RichSection"
import { TrustpilotWidgetBase } from '../../TrustpilotWidgets'

interface IProductContentDynamicZoneProps {
  content: Strapi.ProductContentDynamicZone
  actionBinds: { [key: string]: () => void }
}

const ProductContentDynamicZone = (props: IProductContentDynamicZoneProps) => {
  const { content, actionBinds } = props

  return (
    <>
      {content.map((d: any) => {
        const typeName =
          INTERNAL_TYPE_TO_STRAPI[d.__typename] ||
          d.__typename ||
          STRAPI_TYPE_TO_TYPENAME[d.strapi_component]
        if (typeName === "Strapi_ComponentProductProductQuote")
          return <ProductQuote key={d.id} {...(d as Strapi.ProductQuote)} />
        if (typeName === "Strapi_ComponentProductProductHighlight")
          return (
            <ProductHighlight key={d.id} {...(d as Strapi.ProductHighlight)} />
          )
        if (typeName === "Strapi_ComponentLayoutRichSection")
          return <RichSection key={d.id} {...(d as Strapi.RichSection)} />
        if (typeName === "Strapi_ComponentProductVideo")
          return <ProductVideo key={d.id} {...(d as Strapi.ProductVideo)} />
        if (typeName === "Strapi_ComponentProductProductCta")
          return (
            <ProductCTA
              key={d.id}
              {...(d as Strapi.ProductCTA)}
              actionBinds={actionBinds}
            />
          )
        if (typeName === "Strapi_ComponentWidgetsTrustpilotWidget")
          return (
            <TrustpilotWidgetBase
              key={d.id}
              {...(d)}
            />
          )
        return null
      })}
    </>
  )
}

export default ProductContentDynamicZone
