import { animated } from "react-spring"
import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  color: #662d91;
  padding: 0 40px;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 60px;
  margin-top: 40px;
  .product-images {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    > :first-child {
      width: 100%;
      position: relative;
    }
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
    > :first-child {
      background-color: rgb(235, 235, 235);
      border-radius: 20px;
    }
  }
  .product-images--actions {
    height: 50px;
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .product-info--name {
    color: #662d91;
    font-size: 40px;
    font-size: bold;
    margin-top: 10px;
    height: 172px;
    margin: 10px 0 0 0;
    line-height: 1.2;
    @media (max-width: 1100px) {
      height: auto;
    }
  }
  .product-info {
    position: relative;
    ${(props: { readMore: boolean }) =>
      !props.readMore &&
      css`
        max-height: 550px;
      `};
    overflow: hidden;
  }
  .product-info--bullets {
    margin-top: 12px;
    margin: 0;
    font-size: 18px;
    padding-left: 20px;
    margin-top: 17px;
  }
  .product-info--price-info {
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 10px;
  }
  .product-info--price-info--price {
    color: #000;
    font-size: 30px;
    font-weight: bolder;
  }
  .product-info--price-info--info {
    margin-top: 22px;
  }
  .product-info--actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    > * { margin-right: 15px; }
    > :last-child { margin-right: 0; }
    @media (max-width: 951px) {
      > * { margin-right: 15px; margin-bottom: 15px; }
      > :last-child { margin-right: 0; margin-bottom: 0; }
    }
  }
  .product-info--description {
    color: #444;
    margin-top: 25px;
    font-size: 14px;
    > :first-child {
      margin-bottom: 20px;
    }
  }

  .product-info--readmore {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #087bb1;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    ${(props: { readMore: boolean }) =>
      !props.readMore &&
      css`
        position: absolute;
        bottom: 0;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 75%,
          rgba(255, 255, 255, 0) 100%
        );
      `};
  }

  @media (max-width: 528px) {
    .product-info--name {
      font-size: 36px;
    }
  }

  @media (max-width: 405px) {
    .product-info--name {
      font-size: 30px;
    }
  }
`

export const EditQuantity = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
`

export const ImageSlide = styled(animated.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  img {
    object-fit: contain !important;
    pointer-events: none;
  }
  video {
    width: 100%;
    height: 100%;
    margin: auto auto;
  }
`

export const FullscreenButton = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`
