import Button from "components/UI/Button"
import RatioBox from "components/UI/RatioBox"
import { GatsbyImage } from "gatsby-plugin-image"
import { PlayVideo } from "nzk-react-components"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

const Wrapper = styled.div`
  max-width: 1100px;
  z-index: 2;
  margin: 0 auto;
  margin-bottom: 25px;
  width: calc(100% - 80px);
  cursor: pointer;
  .gatsby-image-wrapper {
    position: initial;
  }
  border-radius: 25px;
  overflow: hidden;
`

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`

const Content = styled.div``

const PlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const ProductVideo = (props: Strapi.ProductVideo) => {
  const [playing, setPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (videoRef.current && playing) videoRef.current.play()
  }, [playing])

  return (
    <Wrapper>
      <RatioBox ratio="16:9">
        <Content>
          {props.thumbnail && !playing && (
            <Thumbnail onClick={() => setPlaying(true)}>
              <GatsbyImage
                image={getGatsbyImage(props.thumbnail?.localFile)}
                alt={props.thumbnail?.alternativeText}
              />
              <PlayIcon>
                <Button round theme="primary" size="x-large">
                  <PlayVideo />
                </Button>
              </PlayIcon>
            </Thumbnail>
          )}
          <Video controls ref={videoRef} onPause={() => setPlaying(false)}>
            {props.sources.map((source, i) => (
              <source key={i} src={source.url} type={source.mime} />
            ))}
          </Video>
        </Content>
      </RatioBox>
    </Wrapper>
  )
}

export default ProductVideo
