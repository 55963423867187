import { ArrowRight, IconButton } from "nzk-react-components"
import React, { useMemo } from "react"
import styled from "styled-components"
import useScroll from "../../hooks/useScroll"

const Wrapper = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 5;
`

const UpArrow = styled.div`
  transform-origin: 55% 45%;
  transform: rotate(-90deg);
`

const ScrollTopButton = () => {
  const { scroll, scrollTop } = useScroll()

  const visible = useMemo(() => {
    if (typeof window === "undefined") return false
    if (scroll.top > window.innerHeight) return true
    return false
  }, [scroll])

  if (!visible) return null
  return (
    <Wrapper>
      <IconButton
        icon={
          <UpArrow>
            <ArrowRight height='100%' width='100%' />
          </UpArrow>
        }
        size="small"
        theme="primary"
        onClick={() => scrollTop(0)}
      >
        Scroll to top
      </IconButton>
    </Wrapper>
  )
}

export default ScrollTopButton
