import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { Button, Close, Shop } from "nzk-react-components"
import React from "react"
import styled from "styled-components"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #ebebeb;
  padding: 20px 55px;
  min-height: 260px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > :first-child {
    position: absolute;
    top: -15px;
    right: -15px;
  }
`

const Actions = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  > :first-child {
    margin: 0 auto;
    margin-bottom: 45px !important;
  }
  > * {
    display: block;
    margin-bottom: 30px !important;
  }
  > :last-child {
    margin-bottom: 10px !important;
  }
`

interface IProps {
  dismiss: () => void
}

const CheckoutModal = (props: IProps) => {
  const { dismiss } = props
  const location = useLocation()

  return (
    <Overlay onClick={() => dismiss()}>
      <Wrapper onClick={e => e.stopPropagation()}>
        <Button size="regular" theme="red" round onClick={() => dismiss()}>
          <Close />
        </Button>
        <Actions>
          <Button size="x-large" round theme="purple">
            <Shop />
          </Button>
          <Button
            theme="purple"
            size="regular"
            onClick={() => {
              dismiss()
              navigate("/shop/checkout")
            }}
          >
            Checkout now
          </Button>
          <Button
            theme="primary"
            size="small"
            onClick={() => {
              dismiss()
              navigate(location.pathname)
            }}
          >
            Continue shopping
          </Button>
        </Actions>
      </Wrapper>
    </Overlay>
  )
}

export default CheckoutModal
