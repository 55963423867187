import { useSprings, animated } from "react-spring"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import { useDrag } from "react-use-gesture"
import { Button, Close } from "nzk-react-components"

const Wrapper = styled.div`
  width: 90vw;
  height: 90vh;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  overflow: auto;
  display: flex;
  box-shadow: 0 5px 0 #afafaf, 0 9px 0 rgba(0, 0, 0, 0.4);
`

const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const Slide = styled(animated.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  > :first-child {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: contain !important;
    pointer-events: none;
    height: 100%;
    width: 100%;
  }
  video {
    width: 100%;
    height: 100%;
    margin: auto auto;
  }
`

const Selectors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  height: 100%;
  background-color: #ebebeb;
  padding: 20px 0px;
  overflow: auto;
  > * {
    margin-bottom: 15px;
  }
`

const Selector = styled.div`
  ${(props: { selected: boolean }) =>
    props.selected &&
    css`
      background-color: #fff;
    `}
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  > :first-child {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: contain !important;
    pointer-events: none;
    height: 100%;
    width: 100%;
  }
`

const QuitButton = styled.div`
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 10;
`

export interface IFullscreenThumbnailProps {
  images: any[]
  dismiss?: () => void
}

const FullscreenThumbnail = (props: IFullscreenThumbnailProps) => {
  const [currentImage, setCurrentImage] = useState(0)
  const index = useRef(0)
  const imagesRef = useRef<HTMLDivElement | null>(null)

  const CAROUSEL_WIDTH = () => {
    let WIDTH = 0
    if (imagesRef.current) {
      WIDTH = imagesRef.current.offsetWidth
    }
    return WIDTH
  }

  const [spring, setSpring] = useSprings(props.images.length, i => ({
    x: (i + 1) * 500,
    scale: 1,
    display: "flex",
  }))

  useEffect(() => {
    setSpring(i => {
      const x = (i - currentImage) * CAROUSEL_WIDTH()
      return { x, display: "flex" }
    })
  }, [])

  useEffect(() => {
    index.current = currentImage
    setSpring(i => {
      const x = (i - currentImage) * CAROUSEL_WIDTH()
      return { x, display: "flex" }
    })
  }, [currentImage])

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
      if (active && xDir > 0 && index.current === 0) {
        cancel()
      }
      if (active && distance > CAROUSEL_WIDTH() / 2) {
        cancel()
        index.current = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          props.images.length - 1
        )
        setCurrentImage(index.current)
      }
      setSpring(i => {
        if (i < index.current - 1 || i > index.current + 1)
          return { display: "none" }
        const x = (i - index.current) * CAROUSEL_WIDTH() + (active ? mx : 0)
        const scale = active ? 1 - distance / CAROUSEL_WIDTH() / 2 : 1
        return { x, scale, display: "flex" }
      })
    }
  )

  const onNextImage = () => {
    setCurrentImage(c => (c + 1) % props.images.length)
  }

  return (
    <Wrapper>
      {props.dismiss && (
        <QuitButton onClick={() => props.dismiss && props.dismiss()}>
          <Button theme="red" size="regular" round>
            <Close />
          </Button>
        </QuitButton>
      )}
      <Selectors>
        {props.images.map((image, i) => (
          <Selector
            key={i}
            selected={currentImage === i}
            onClick={() => setCurrentImage(i)}
          >
            <GatsbyImage
              image={getGatsbyImage(image.localFile) as IGatsbyImageData}
              alt={image.alternativeText}
            />
          </Selector>
        ))}
      </Selectors>
      <Content ref={imagesRef}>
        {spring.map(({ x, display }, i) => {
          const item = props.images[i]
          return (
            <Slide
              style={{ x, display }}
              {...bind()}
              key={item.id}
              onClick={onNextImage}
            >
              {item.mime === "video/mp4" ? (
                <video controls>
                  <source src={item.url} type={item.mime} />
                </video>
              ) : (
                <GatsbyImage
                  image={getGatsbyImage(item.localFile) as IGatsbyImageData}
                  alt={item.alternativeText}
                />
              )}
            </Slide>
          )
        })}
      </Content>
    </Wrapper>
  )
}

FullscreenThumbnail.defaultProps = {
  dismiss: null,
}

export default FullscreenThumbnail
