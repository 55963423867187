import { GatsbyImage } from "gatsby-plugin-image"
import { Heart } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

const Wrapper = styled.div`
  position: relative;
  color: #fff;
  max-width: 1100px;
  margin: 25px auto;
  border-radius: 20px;
  overflow: hidden;
  width: calc(100% - 80px);
`

const Content = styled.div`
  display: flex;
  min-height: 254px;
  padding: 20px 37px;
  .product-quote--quote {
    margin: auto 0;
    font-size: 22px;
    line-height: 26px;
    flex: 1;
    .quote--author {
      display: flex;
      align-items: center;
      margin-top: 15px;
      > :first-child {
        margin-right: 8px;
      }
      @media (max-width: 720px) {
        display: flex;
        justify-content: center;
      }
    }
  }
  .product-quote--image {
    position: relative;
    width: 100%;
    flex: 1;
    > :first-child {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: 720px) {
    flex-direction: column;
    .product-quote--quote {
      text-align: center;
    }
    .product-quote--image {
      min-height: 200px;
    }
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  > * {
    height: 100%;
    width: 100%;
  }
`

const ProductQuote = (props: Strapi.ProductQuote) => (
  <Wrapper>
    <Background>
      <GatsbyImage
        image={getGatsbyImage(props.backgroundImage.localFile)}
        alt={props.backgroundImage.alternativeText}
        objectFit="cover"
        objectPosition="50% 50%"
      />
    </Background>
    <Content>
      <div className="product-quote--quote">
        <div className="quote--text">"{props.quote.value}"</div>
        <div className="quote--author">
          <Heart width="20px" height="20px" />
          {props.quote.authorName}
          {props.quote.authorTitle ? `${props.quote.authorTitle}` : ""}
        </div>
      </div>
      <div className="product-quote--image">
        <GatsbyImage
          image={getGatsbyImage(props.image.localFile)}
          alt={props.image.alternativeText}
          objectFit="contain"
        />
      </div>
    </Content>
  </Wrapper>
)

export default ProductQuote
