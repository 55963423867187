import { GatsbyImage } from "gatsby-plugin-image"
import { Star, StarHalf } from "grommet-icons"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

export const Wrapper = styled.section`
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 60px;
  width: calc(100% - 80px);
  .reviews--title {
    color: #662d91;
    font-size: 40px;
    margin-bottom: 34px;
  }

  .reviews--header {
    margin-bottom: 38px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .reviews--rating {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .reviews--rating-visuals {
    margin-right: 20px;
  }

  .reviews--rating-rate {
    font-size: 18px;
    span {
      font-size: 35px;
    }
  }

  .reviews--rating-nbreviews {
    position: relative;
    margin-left: 20px;
    padding-bottom: 5px;
    :before {
      position: absolute;
      content: "";
      top: calc(50% - 6px);
      left: -12px;
      height: 6px;
      width: 6px;
      background-color: #000;
      border-radius: 50%;
    }
  }

  .reviews--service {
    margin-left: auto;
    @media (max-width: 760px) {
      margin-left: 0;
    }
  }
`

const Reviews = styled.div`
  > * {
    margin-bottom: 25px;
  }
`

const Review = styled.div`
  background-color: rgb(235, 235, 235);
  border-radius: 20px;
  padding: 25px 29px;
  color: #3c3c3b;
  .review--name {
    margin-bottom: 20px;
  }
  .review--rating {
    margin-bottom: 20px;
  }
  .review--text {
  }
`

interface IProductReviewsProps {
  productReviews: Strapi.ProductReviews
}

const ProductReviews = (props: IProductReviewsProps) => {
  const { productReviews } = props

  const getStars = (rate, size = "49px") => (
    <>
      {[null, null, null, null, null].map((_, i) => {
        if (i + 1 <= rate) return <Star key={i} color="#FAC348" size={size} />
        if (i + 0.5 === rate)
          return <StarHalf key={i} color="#FAC348" size={size} />
        return <Star key={i} color="#E0E0E0" size={size} />
      })}
    </>
  )

  return (
    <Wrapper>
      <div className="reviews--title">{productReviews.title}</div>
      <div className="reviews--header">
        <div className="reviews--rating">
          <div className="reviews--rating-visuals">
            {getStars(productReviews.rating)}
          </div>
          <div className="reviews--rating-rate">
            <span>{productReviews.rating}</span> / 5
          </div>
          <div className="reviews--rating-nbreviews">
            {productReviews.nbReviews} reviews
          </div>
        </div>
        {productReviews.reviewsUrl && productReviews.reviewsServiceImage && (
          <div className="reviews--service">
            <a
              href={productReviews.reviewsUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={getGatsbyImage(
                  productReviews.reviewsServiceImage.localFile
                )}
                objectFit="cover"
                alt={productReviews.reviewsServiceImage.alternativeText}
              />
            </a>
          </div>
        )}
      </div>
      <Reviews>
        {productReviews.reviews.map(review => (
          <Review key={review.id}>
            <div className="review--name">{review.name}</div>
            <div className="review--rating">
              {getStars(review.rating, "33px")}
            </div>
            <div className="review--text">{review.text}</div>
          </Review>
        ))}
      </Reviews>
    </Wrapper>
  )
}

export default ProductReviews
