import { formatAmount } from "components/Strapi/StrapiPlanHero"
import RatioBox from "components/UI/RatioBox"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { ArrowLeft, ArrowRight, Button, Minus, Plus, Search } from "nzk-react-components"
import { navigate } from "gatsby"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import Markdown from "react-markdown"
import { useSprings } from "react-spring"
import { useDrag } from "react-use-gesture"
import rehypeRaw from "rehype-raw"
import getGatsbyImage from "utils/getGatsbyImage"
import { useShopLocaleState } from "../../../providers/ShopLocaleProvider"
import useModal from "../../../hooks/useModal"
import FullscreenThumbnail from "./FullscreenThumbnail"
import * as s from "./index.styles"

interface IProductHeroProps {
  product: Strapi.ShopProduct
  extraButtons?: ReactElement[]
  onAddItem: () => void
  quantity: number
  incrementItem: () => void
  decrementItem: () => void
}

const ProductHero = (props: IProductHeroProps) => {
  const { product, onAddItem, quantity, incrementItem, decrementItem } = props
  const [readMore, setReadMore] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const { locale } = useShopLocaleState()
  const index = useRef(0)
  const imagesRef = useRef<HTMLDivElement | null>(null)

  const skus = product.skus
    .map(s => ({
      ...s,
      pricings: s.pricings?.filter(
        p => p.countries.map(l => l.locale).indexOf(locale) >= 0
      ),
    }))
    .filter(s => s.pricings?.length > 0)
  const sku = skus[0]

  const pricings =
    sku?.pricings?.filter(
      p => p.countries.map(l => l.locale).indexOf(locale) >= 0
    ) || []

  const pricing: Strapi.ShopProductPrice = pricings[0]

  const images = [
    sku?.thumbnail,
    product?.thumbnail,
    ...(sku?.images || []),
    ...(product.images || []),
  ].filter(d => d)

  const fullWidthImages = [
    // @ts-ignore
    sku?.fullWidthThumbnail,
    // @ts-ignore
    product?.fullWidthThumbnail,
    // @ts-ignore
    ...(sku?.fullWidthImages || []),
    // @ts-ignore
    ...(product.fullWidthImages || []),
  ].filter(d => d)

  const onNextImage = () => {
    setCurrentImage(c => (c + 1) % images.length)
  }

  const onPreviousImage = () => {
    setCurrentImage(c => (c === 0 ? images.length - 1 : c - 1))
  }

  const formattedPrice = pricing
    ? formatAmount(pricing?.currency, pricing?.amount)
    : ""

  const CAROUSEL_WIDTH = () => {
    let WIDTH = 0
    if (imagesRef.current) {
      WIDTH = imagesRef.current.offsetWidth
    }
    return WIDTH
  }

  const [spring, setSpring] = useSprings(images.length, i => ({
    x: (i + 1) * 500,
    scale: 1,
    display: "block",
  }))

  useEffect(() => {
    setSpring(i => {
      const x = (i - currentImage) * CAROUSEL_WIDTH()
      return { x, display: "block" }
    })
  }, [])

  useEffect(() => {
    index.current = currentImage
    setSpring(i => {
      const x = (i - currentImage) * CAROUSEL_WIDTH()
      return { x, display: "block" }
    })
  }, [currentImage])

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
      if (active && xDir > 0 && index.current === 0) {
        cancel()
      }
      if (active && distance > CAROUSEL_WIDTH() / 2) {
        cancel()
        index.current = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          images.length - 1
        )
        setCurrentImage(index.current)
      }
      setSpring(i => {
        if (i < index.current - 1 || i > index.current + 1)
          return { display: "none" }
        const x = (i - index.current) * CAROUSEL_WIDTH() + (active ? mx : 0)
        const scale = active ? 1 - distance / CAROUSEL_WIDTH() / 2 : 1
        return { x, scale, display: "block" }
      })
    }
  )

  const { Modal: FullscreenThumbnailModal, open } = useModal(
    <FullscreenThumbnail images={fullWidthImages} />
  )

  return (
    <s.Wrapper readMore={readMore} id="product-hero">
      {FullscreenThumbnailModal}
      <div className="product-images" ref={imagesRef}>
        <RatioBox ratio="1:1">
          {spring.map(({ x, display }, i) => {
            const item = images[i]
            return (
              <s.ImageSlide
                style={{ x, display }}
                {...bind()}
                key={item.id}
                onClick={onNextImage}
              >
                {item.mime === "video/mp4" ? (
                  <video controls>
                    <source src={item.url} type={item.mime} />
                  </video>
                ) : (
                  <GatsbyImage
                    image={getGatsbyImage(item.localFile) as IGatsbyImageData}
                    alt={item.alternativeText}
                  />
                )}
              </s.ImageSlide>
            )
          })}
          <s.FullscreenButton>
            <Button size="small" round theme="orange" onClick={open}>
              <Search />
            </Button>
          </s.FullscreenButton>
        </RatioBox>
        <div className="product-images--actions">
          {images.length > 1 && (
            <>
              <Button
                size="small"
                round
                theme="primary"
                onClick={onPreviousImage}
              >
                <ArrowLeft />
              </Button>
              <Button size="small" round theme="primary" onClick={onNextImage}>
                <ArrowRight />
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="product-info">
        <h1 className="product-info--name">{product.name || sku.title}</h1>
        <div className="product-info--price-info">
          <div className="product-info--price-info--price">
            {formattedPrice}
          </div>
          <div className="product-info--price-info--info">Tax included</div>
          {product.productBullets && (
            <ul className="product-info--bullets">
              {product.productBullets?.map((bullet, i) => (
                <li key={i}>{bullet.value}</li>
              ))}
            </ul>
          )}
        </div>
        <div className="product-info--actions">
          {product.addToBasketButton ? (
            <Button
              theme="primary"
              size="regular"
              onClick={() => navigate(product.addToBasketButton!.targetUrl)}
            >
              {product.addToBasketButton!.value}
            </Button>
          ) : (
            <>
              {quantity >= 1 ? (
                <s.EditQuantity>
                  <div>Quantity: {quantity}</div>
                  <Button
                    size="x-small"
                    theme="purple"
                    round
                    onClick={() => decrementItem()}
                  >
                    <Minus />
                  </Button>
                  <Button
                    size="x-small"
                    theme="purple"
                    round
                    onClick={() => incrementItem()}
                  >
                    <Plus />
                  </Button>
                </s.EditQuantity>
              ) : (
                <Button
                  size="regular"
                  theme="primary"
                  onClick={() => onAddItem()}
                >
                  Add to basket
                </Button>
              )}
            </>
          )}
          {
            (props.extraButtons || []).map(b => b)
          }
        </div>
        <div className="product-info--description expanded">
          <div>Product description</div>
          <Markdown rehypePlugins={[rehypeRaw]}>
            {product.description || sku.description || ""}
          </Markdown>
        </div>
        <div
          className="product-info--readmore"
          onClick={() => setReadMore(d => !d)}
          role="button"
          tabIndex={0}
          onKeyPress={e => e.which === 13 && setReadMore(d => !d)}
        >
          Read {readMore ? "less" : "more"}
        </div>
      </div>
    </s.Wrapper>
  )
}

ProductHero.defaultProps = {
  extraButtons: []
}

export default ProductHero
