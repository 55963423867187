import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'
import getGatsbyImage from 'utils/getGatsbyImage'

const Wrapper = styled.div`
  display: flex;
  max-width: 1100px;
  margin: 25px auto;
  > * { margin-right: 30px; }
  > :last-child { margin-right: 0px; }

  ${(props: { flipped: boolean }) => props.flipped ? css`
    flex-direction: row-reverse;
    > * { margin-left: 30px; }
    > :last-child { margin-left: 0px; }
    @media (max-width: 720px) {
      flex-direction: column;
      > :first-child { margin-bottom: 20px; }
      > * { margin-left: 0px; }
      > :last-child { margin-left: 0px; }
    }
  ` : css`
    > * { margin-right: 30px; }
    > :last-child { margin-right: 0px; }
    @media (max-width: 720px) {
      flex-direction: column;
      > :first-child { margin-bottom: 20px; }
      > * { margin-right: 0px; }
      > :last-child { margin-right: 0px; }
    }
  `}
  width: calc(100% - 80px);
`

const Column = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 720px) {
    text-align: center;
  }
`

const fontStyles = (styles: Strapi.FontStyles) => css`
  color: ${styles.color || 'initial'};
  font-size: ${styles.fontSize || 'initial'};
  line-height: ${styles.lineHeight || 'initial'};
`

const Text = styled.div`
  ${(props: { fontStyles: Strapi.FontStyles }) => fontStyles(props.fontStyles)};
`

const Title = styled(Text)`
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
`

const ImageWrapper = styled.div`
  max-height: 373px;
  @media (max-width: 720px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const ProductHighlight = (props: Strapi.ProductHighlight) =>
  <Wrapper flipped={props.flipped}>
    <Column>
      {props.title && <Title fontStyles={props.FontStyles || {}}>{props.title.value}</Title> }
      {props.text && <Text fontStyles={props.FontStyles || {}}>{props.text.value}</Text> }
    </Column>
    <Column>
      <ImageWrapper>
        <GatsbyImage image={getGatsbyImage(props.image.localFile)} alt={props.image.alternativeText} />
      </ImageWrapper>
    </Column>
  </Wrapper>

export default ProductHighlight

