import Button from "components/UI/Button"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .product-cta--title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px;
    color: #662d91;
    font-weight: bold;
  }
`

interface ProductCTAProps extends Strapi.ProductCTA {
  actionBinds: { [key: string]: () => void }
}

const ProductCTA = (props: ProductCTAProps) => {
  const { actionBinds } = props

  const onClick = useMemo(
    () =>
      actionBinds[props.button.targetUrl] ||
      (() => navigate(props.button.targetUrl)),
    [actionBinds]
  )

  return (
    <Wrapper>
      {props.title && (
        <div className="product-cta--title">{props.title?.value}</div>
      )}
      <Button
        theme={props.button.theme || "primary"}
        size="regular"
        onClick={onClick}
      >
        {props.button.value}
      </Button>
    </Wrapper>
  )
}

export default ProductCTA
