import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Button } from "nzk-react-components"
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useSprings } from "react-spring"
import { useDrag } from "react-use-gesture"
import getGatsbyImage from "utils/getGatsbyImage"
import useInterval from "../../hooks/useInterval"
import useWindowSize from "../../hooks/useWindowSize"
import * as s from "./index.styles"

interface IProps {
  slides: {
    backgroundImage: Strapi.UploadFile
    title: string
    image: Strapi.UploadFile
    button?: {
      label: string
      target: string
      external: boolean
    }
    BulletItems?: {
      bulletIcon: Strapi.UploadFile
      value: string
    }[]
    richContent?: string
  }[]
}

const CarouselHeader = (props: PropsWithChildren<IProps>) => {
  const { slides } = props
  const [currentIndex, setCurrentIndex] = useState(0)
  const index = useRef(0)
  const { width } = useWindowSize()

  const [spring, setSpring] = useSprings(slides.length, i => ({
    x: i * width,
    scale: 1,
    display: "block",
    zIndex: index.current === i ? 1 : 0,
  }))

  const reposition = useCallback(() => {
    setSpring(i => {
      const x = (i - currentIndex) * width
      return { x, display: "block", zIndex: index.current === i ? 1 : 0 }
    })
  }, [currentIndex, width])

  useEffect(() => {
    reposition()
  }, [width])

  const BulletIcons = (slides[currentIndex].BulletItems || []).filter(
    item => item.bulletIcon
  )
  const BulletIcon = BulletIcons && BulletIcons[0] && BulletIcons[0].bulletIcon

  const selectSlide = index => {
    setCurrentIndex(index)
  }

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
      if (
        (active && xDir > 0 && index.current === 0) ||
        (xDir < 0 && index.current === slides.length - 1)
      ) {
        cancel()
      }
      if (active && distance > width / 3) {
        cancel()
        index.current = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          slides.length - 1
        )
        setCurrentIndex(index.current)
      }
      setSpring(i => {
        if (i < index.current - 1 || i > index.current + 1)
          return { display: "none" }
        const x = (i - index.current) * width + (active ? mx : 0)
        const scale = active ? 1 - distance / width / 3 : 1
        return {
          x,
          scale,
          display: "block",
          zIndex: index.current === i ? 1 : 0,
        }
      })
    }
  )

  useInterval(
    () => {
      setCurrentIndex(i => (i + 1) % slides.length)
    },
    8000,
    [currentIndex, slides]
  )

  useEffect(() => {
    index.current = currentIndex
    reposition()
  }, [currentIndex])

  if (!slides || slides.length === 0) return null

  const getSlideContent = item =>
    item && (
      <s.Content>
        <s.Column>
          <h2 className="slide--title">{item.title}</h2>
          {(item.BulletItems || []).length > 0 && BulletIcon && (
            <s.BulletItems>
              {(item.BulletItems || []).map((item, i) => (
                <s.BulletItem key={i}>
                  <GatsbyImage
                    draggable={false}
                    image={getGatsbyImage(BulletIcon.localFile)}
                    alt={BulletIcon.alternativeText || ""}
                  />
                  {item.value}
                </s.BulletItem>
              ))}
            </s.BulletItems>
          )}
          {item.button && (
            <Link to={item.button.target} draggable={false}>
              <Button size="regular" theme="primary">
                {item.button.label}
              </Button>
            </Link>
          )}
        </s.Column>
        <s.Column
          className="slide-picture"
          style={{ justifyContent: "center" }}
        >
          <s.Picture>
            {item.image && (
              <GatsbyImage
                image={getGatsbyImage(item.image.localFile) as IGatsbyImageData}
                objectFit="contain"
                objectPosition="50% 100%"
                alt={item.image.alternativeText || ""}
              />
            )}
          </s.Picture>
        </s.Column>
      </s.Content>
    )

  return (
    <s.Wrapper className="carousel-header">
      <s.Container>
        {props.children}
        {spring.map(({ x, display, zIndex }, i) => (
          <s.Slide
            className="slide"
            {...bind()}
            key={i}
            style={{ display, x, zIndex }}
          >
            <s.Background>
              <GatsbyImage
                image={
                  getGatsbyImage(
                    slides[i].backgroundImage.localFile
                  ) as IGatsbyImageData
                }
                alt={slides[i].backgroundImage.alternativeText || ""}
              />
            </s.Background>
            {getSlideContent(slides[currentIndex])}
          </s.Slide>
        ))}
        <s.Pusher>{getSlideContent(slides[currentIndex])}</s.Pusher>
        {props.slides.length > 1 && (
          <s.Dots>
            {props.slides.map((_, i) => (
              <s.Dot
                key={i}
                selected={currentIndex === i}
                onClick={() => selectSlide(i)}
              />
            ))}
          </s.Dots>
        )}
      </s.Container>
    </s.Wrapper>
  )
}

CarouselHeader.defaultProps = {}

export default CarouselHeader
